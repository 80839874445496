<template>
  <div class="row">
    <div class="col-md-12 px-7">
      <b-card class="mb-7 row" id="filter-card">
        <form action="" @submit.prevent="filterResult">
          <div class="col-lg-12 col-xl-12 px-0" v-if="filters">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-5 col-md-5 col-sm-12 row px-0 mx-0 d-flex justify-content-start items-center">
             <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
               <i class="fa fa-chevron-left"></i>
             </span>
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="filters.search"
                ></input-filter>
              </div>
              <div class="col-lg-5 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      title="general.start_date"
                      :model.sync="filters.start_date"
                      name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      title="general.end_date"
                      :model.sync="filters.end_date"
                      name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="my-lg-2 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-card>
    </div>
    <div class="col-md-12 mb-4 mt-n3">
      <div class="w-100 d-flex justify-content-center">
        <div class="w-lg-75 w-md-100 w-sm-100 w-100 row text-center font-weight-boldest text-xl">
          <div :class="{'bg-primary text-white custom-tab-box': +currentActiveTab === 1}"
               class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-sm-3 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-right-0 border-radius-left-bottom-10px border-radius-left-top-10px"
               @click="currentActiveTab = 1">
            {{ $t('general.waiting_tasks') }}
          </div>
          <div :class="{'bg-primary text-white custom-tab-box': +currentActiveTab === 2}"
               class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center items-center cursor-pointer py-2 primary-border-1px border-radius-right-bottom-10px border-radius-right-top-10px"
               @click="currentActiveTab = 2">
            {{ $t('general.completed_tasks') }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 pt-3">
      <dashboard-box :title="$t('general.tasks')">
        <template v-slot:preview>
          <i class="fa fa-chevron-left position-absolute top-n10" @click="onClickBack" v-if="+level === 2">{{ $t('general.back') }}</i>
          <div class="row px-5">
            <h2 v-if="totalTaskCount === 0"
                class="text-center py-10 col-12">
              {{ $t('sample.you_have_no_task') }}
            </h2>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-for="task in sampleTasks" v-if="+level === 1">
              <task-index-card
                  :task="task"
                  :noOfTask="1"
                  :task-type="1"
                  :meetingId="Number(task.meeting_id) !== 0 ? task.meeting_id : '-'"
                  :meetingName="Number(task.meeting_id) !== 0 ? task.name : '-'"
                  :sampleCreator="task.user.name"
                  :clientName="task.company.name"
                  :orderDate="task.order_date"
                  :requstId="null"
                  @onClickTaskIndexCard="onClickTaskIndexCard"
                  :request-id="null"
                  :is-requested="task.is_completed"
              ></task-index-card>
            </div>
            <template v-if="isUserGranted('Staff', ['brandManager', 'brandChief'], false)">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-for="sample in fabricRequestTasks"
                   v-show="level === 1 || (level === 2 && activeItem.requestType === 2 && activeItem.requestId === sample.collection_number)">
                <task-index-card
                    :task="sample"
                    :noOfTask="sample.total"
                    :task-type="2"
                    :sample-name="sample.name"
                    :brand-manager="sample.brand_manager"
                    :meetingId="Number(sample.meeting_id) !== 0 ? sample.meeting_id : '-'"
                    :meetingName="Number(sample.meeting_id) !== 0 ? sample.name : '-'"
                    :sampleCreator="sample.user ? sample.user.name : '-'"
                    :clientName="sample.company ? sample.company.name : '-'"
                    :orderDate="sample.order_date"
                    :requestId="sample.requestId"
                    :fabric-name="sample.fabric_type"
                    :color="sample.color"
                    :is-requested="sample.is_requested"
                    @onClickTaskIndexCard="onClickTaskIndexCard"
                ></task-index-card>
              </div>
            </template>
            <template v-if="isUserGranted('Staff', ['accessoryStaff'], false)">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 py-3" v-for="sample in accessoryRequestTasks"
                   v-show="level === 1 || (level === 2 && activeItem.requestType === 3 && activeItem.requestId === sample.collection_number)">
                <task-index-card
                    :task="sample"
                    :noOfTask="sample.total"
                    :task-type="3"
                    :sample-name="sample.name"
                    :meetingId="Number(sample.meeting_id) !== 0 ? sample.meeting_id : '-'"
                    :meetingName="Number(sample.meeting_id) !== 0 ? sample.name : '-'"
                    :sampleCreator="sample.user ? sample.user.name : '-'"
                    :clientName="sample.company ? sample.company.name : '-'"
                    :orderDate="sample.order_date"
                    :requestId="sample.requestId"
                    :itemId="sample.accessoryId"
                    :is-requested="sample.is_requested"
                    @onClickTaskIndexCard="onClickTaskIndexCard"
                ></task-index-card>
              </div>
            </template>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import TaskIndexCard from "@/view/pages/samples/layout/task/TaskIndexCard";
import InputFilter from "@/assets/components/filters/InputFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import Paginate from "@/assets/components/dataTable/Pagination";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex';
import {GET_ITEMS} from "@/core/services/store/REST.module";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, LARAVEL_RAW_DATE_TIME_FORMAT} from "@/core/config/constant";
import {
  FILTER_TASK,
  SET_FILTER_TASK,
} from "@/core/services/store/sample/samples.index.module";
import * as _ from "lodash";
export default {
  name: "TaskIndex",
  components: {
    DashboardBox,
    TaskIndexCard,
    InputFilter,
    DateFilter,
    Paginate
  },
  data() {
    return {
      currentActiveTab: 1,
      fabricRequestTasks: [],
      accessoryRequestTasks: [],
      sampleTasks: [],
      level: 1,
      activeItem: {
        requestType: null,
        requestId: null
      }
    }
  },
  computed: {
    ...mapGetters({
      'filter': FILTER_TASK
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },
    totalTaskCount(){
      let total = 0;
      if (this.isUserGranted('Staff', ['customerRepresentative', 'collectionResponsible'], false)) {
        total += this.sampleTasks.length;
      }
      if (this.isUserGranted('Staff', ['brandManager', 'brandChief'], false)) {
        total += this.fabricRequestTasks.length;
      }
      if (this.isUserGranted('Staff', ['accessoryStaff'], false)) {
        total += this.accessoryRequestTasks.length;
      }

      return total;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.sample"), route: "sample"},
      {title: this.$t("general.tasks")}
    ]);

    if (!this.filter) {
      this.currentActiveTab = 1;
      this.resetFilters();
    } else {
      if (this.filter.is_requested) {
        this.currentActiveTab = 2;
      }

      if (this.filter.is_completed) {
        this.currentActiveTab =  2
      }
    }
    this.filterResult();
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER_TASK,
    }),
    getSampleTasks() {
      if (this.isUserGranted('Staff', ['customerRepresentative', 'collectionResponsible'], false)) {
        const self = this;

        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
        delete filters.is_requested;

        this.$store.dispatch(GET_ITEMS, {
          url: "api/samples-by-user",
          filters: filters,
        }).then(result => {
          if(result.status) {
            let items = [];
            for(let key in result.data.data){
              let temp = result.data.data[key];
              temp.create_at = moment(temp.created_at, LARAVEL_RAW_DATE_TIME_FORMAT);
              temp.updated_at = moment(temp.updated_at, LARAVEL_RAW_DATE_TIME_FORMAT);
              temp.deleted_at = moment(temp.deleted_at, LARAVEL_RAW_DATE_TIME_FORMAT);
              temp.date_hour = moment(temp.date_hour, LARAVEL_DATE_TIME_FORMAT);
              temp.order_date = moment(temp.order_date, LARAVEL_DATE_FORMAT);
              items.push(temp);
            }
            self.sampleTasks = items;
          }else{
            self.sweetAlertError('general.internal_error');
          }
        })
      }
    },
    getAccessoryRequestTasks(group = true) {
      if (this.isUserGranted('Staff', ['accessoryStaff'], false)) {
        const self = this;

        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
        delete filters.is_completed;

        if (group) {
          this.$set(filters, 'group_by', true);
        } else {
          delete filters.group_by;
        }
        this.$store.dispatch(GET_ITEMS, {
          url: "api/accessory-requests",
          filters: filters,
        }).then(result => {
          if (result.status) {
            let temp = [];
            result.data.data.forEach(request => {
              if (Number(request.is_completed) === 1) return;
              request.accessory_sample.sample.order_date = moment(request.accessory_sample.sample.order_date , LARAVEL_DATE_TIME_FORMAT);
              request.accessory_sample.sample.requestId = request.id;
              request.accessory_sample.sample.accessoryId = request.accessory_sample.id;
              request.accessory_sample.sample.total = request.total;
              temp.push({...request.accessory_sample.sample, accessory: request.accessory_sample.accessory});
            });

            self.accessoryRequestTasks = [...temp];
          }else{
            self.sweetAlertError('general.internal_error');
          }
        });
      }
    },
    getFabricRequestTasks: function (group = true) {
      if (this.isUserGranted('Staff', ['brandManager', 'brandChief'], false)) {
        const self = this;
        let filters = _.cloneDeep(this.filters);
        this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
        this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));

        delete filters.is_completed;

        if (group) {
          this.$set(filters, 'group_by', true);
        } else {
          delete filters.group_by;
        }
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-requests",
          filters: filters
        }).then(result => {
          if (result.status) {
            let temp = [];
            result.data.data.forEach(request => {
              if (Number(request.is_completed) === 1) return;
              request.sample.order_date = moment(request.sample.order_date, LARAVEL_DATE_TIME_FORMAT);
              request.sample.date_hour = moment(request.sample.date_hour, LARAVEL_DATE_TIME_FORMAT);
              request.sample.company = request.company;
              request.sample.requestId = request.id;
              request.sample.user = request.user;
              request.sample.brand_manager = request.brand_manager ? request.brand_manager.name : '-';
              request.sample.total = request.total;
              request.sample.fabric_type = request.sample_product_information ? request.sample_product_information.fabric_type.translation.name : '-';
              request.sample.color = request.sample_product_information ? request.sample_product_information.color : '';
              temp.push(request.sample);
            });
            self.fabricRequestTasks = temp;
          } else {
            self.sweetAlertError('general.internal_error');
          }
        });
      }
    },
    onClickTaskIndexCard: function ({task_id, task_type, request_id, no_of_task, collection_number, item_id}) {
      if (Number(task_type) === 1) {
        this.$router.push({name: "sample.product.edit", params: {id: task_id}})
      } else if (Number(task_type) === 2) {
        if (Number(no_of_task) > 1) {
          this.getFabricRequestTasks(false);
          this.level = 2;
          this.activeItem = {
            requestType: task_type,
            requestId: collection_number
          }
        } else {
          this.$router.push({
            name: request_id === undefined || request_id === null ? "sample.request" : "sample.request.fabric",
            params: {id: task_id, requestType: 1, fabricRequestId: request_id}
          })
        }
      } else {
        if (Number(no_of_task) > 1) {
          this.getAccessoryRequestTasks(false);
          this.level = 2;
          this.activeItem = {
            requestType: task_type,
            requestId: collection_number
          }
        } else {
          this.$router.push({
            name: request_id === undefined || request_id === null ? "sample.request" : "sample.request.accessory",
            params: {id: task_id, requestType: 2, accessoryRequestId: request_id, itemId: item_id}
          })
        }
      }
    },
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        per_page: 999999,
        page: 1,
        search: null,
        is_completed: 0,
        is_requested: 0
      })
    },
    filterResult() {
      this.getSampleTasks();
      this.getFabricRequestTasks();
      this.getAccessoryRequestTasks();
    },
    onClickBack() {
      this.level = 1;
      if (this.activeItem.requestType === 2) {
        this.getFabricRequestTasks();
      }

      if (this.activeItem.requestType === 3) {
        this.getAccessoryRequestTasks();
      }
    }
  },
  watch: {
    currentActiveTab(value) {
      if (value === 1) {
        this.$set(this.filter, 'is_completed', 0)
        this.$set(this.filter, 'is_requested', 0)
      } else {
        this.$set(this.filter, 'is_completed', 1)
        this.$set(this.filter, 'is_requested', 1)
      }
      this.filterResult();

    }
  }
}
</script>

<style scoped>
*:focus {
  outline: none;
}

#filter-card {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.top-n10 {
  top: -10px !important;
}
</style>
